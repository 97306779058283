<template>
  <v-app>
    <v-main>
      <MainHeader/>
      <router-view/>
      <MainFooter/>
    </v-main>
  </v-app>
</template>

<script>

import MainHeader from "./components/MainHeader.vue"
import MainFooter from "./components/MainFooter.vue"

export default {
  name: 'App',

  components: {
    //HelloWorld,
    MainHeader,
    MainFooter,
  },

  data: () => ({
    //
  }),
}
</script>

<style>

.v-slide-group__content {
  justify-content: start;
}

.no_style_a{
  color:#000 !important;
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .v-dialog > .v-overlay__content{
    left:0;
    width: 100% !important;
    max-width: 100% !important;
    margin: 0;
  }

  .mb-tabs{
    width:100%;
  }

  .mb-tabs .v-tab{
    width:40px !important;
    max-width: 40px !important;
    min-width: 40px;
  }
  
  .mb-tabs .v-tab.v-tab {
    min-width: 40px;
  }

  .v-slide-group__content {
    justify-content: space-around;
  }
}
</style>

<template>
    
  <v-divider class="mx-2 text-h4"></v-divider>
	<div class="w-100 d-flex justify-space-between py-2" :style='[ day.isFuture ? "background-color:#f8f8f8;" : "background-color:#fff;"]'>
		<div class="align-self-center d-flex justify-start ml-2">
			<p class="text-h7 d-block">
        {{ day.day.slice(0,2) }}月{{ day.day.slice(-2) }}日({{ day.week }})
      </p>
      <p v-if="day.isWin" class="align-self-center d-block ml-2 px-1 py-1 font-weight-bold text-white rounded-pill" style="background-color:green;font-size:12px;height:20px;line-height: 14px;" >Win5</p>
		</div>
		<div class="d-flex justify-end my-1;">
			<template v-for="(place,col) in day.places" :key="place">
        <router-link class="no_style_a" :aria-label="getAriaLabel(currentRaces[col])" :to="{name: 'Race', params:{date:currentRaces[col].date,place:currentRaces[col].place,round:currentRaces[col].round,name:currentRaces[col].race_name}}">
          <v-btn @click.stop.prevent="clickPlace(currentRaces[col])" class="align-self-center rounded-lg d-block mr-2 place_btn" :style="backColor(place.place)">
            <p class="text-center font-weight-bold" style="" :class="[day.isFuture ? 'text-black' : 'text-white']">{{ value.PLACE_NAME[place.place] }}<br>{{ raceTitle[col] }}</p>
            <p v-if='this.grade[col]' class="rounded-pill text-white font-weight-bold" style="position:absolute;bottom:-4px;right:-6px;padding:0px 4px;background-color:red;" :style="gradeColor(this.grade[col])">G{{ this.grade[col] }}</p>
          </v-btn>
        </router-link>
      </template>
		</div>
	</div>
</template>

<script>
import value from "../../global.js"
    
export default {
  name: 'Race-date-item',
  emits: ['clickPlace'],
  props:{
    day:{
      default:{},
      required:true,
    },
    sec:{
      default:0,
      required:true,
    },
    row:{
      default:0,
      required:true,
    },
  },
  components: {
            
  },
  data: () => ({
    value:value.VALUE,
    grade:[0,0,0],
    raceTitle:[],
    currentRaces:[],
  }),
  methods:{
    clickDay:function(){
      this.$emit("clickPlace",this.sec,this.row,0);
    },
    clickPlace:function(race){
      this.$emit("clickPlace",this.sec,this.row,race);
    },
    getAriaLabel(race){
			const label = race.date.slice(0,4)+"年"+race.date.slice(4,6)+"月"+race.date.slice(-2)+"日 "+this.value.PLACE_NAME[race.place]+race.round+"R "+race.race_name+"の出馬表";
			return label;
		},
  },
  created:function(){
    for(let i=0;i<this.day.places.length;i++){
      let raceName = ""
      let races = this.day.places[i].races;
      var race = null;
      for(var n=0;n<races.length;n++){
        if(races[n].grade > 0){
          this.grade[i] = races[n].grade;
          raceName = races[n].race_name.slice(0,3);
          race = races[n];
          //this.raceTitle[i] = 
        }
        else if(races[n].round == 11){
          raceName = races[n].race_name.slice(0,3);
          this.grade[i] = races[n].grade;
          race = races[n];
        }
      }
      if(race != null){
        this.currentRaces[i] = race;
      }
      else{
        this.currentRaces[i] = races[0];
      }

      if(raceName != "")this.raceTitle[i] = raceName;
    }
    
  },
  computed:{
    backColor(){
      return (place) => {
        return {"background-color":this.value.PLACE_COLORS[place]};
      };
    },
    gradeColor(){
      return (grade) => {
        return {"background-color":this.value.GRADE_COLOR[grade]};
      };
    },
  }
}
</script>

<style scoped>
.place_btn{
	width: 50px !important;
	height: 50px !important;
	min-width:50px;
	/*padding: 0px;
	font-size:14px;
	position:relative;
	box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, .5);*/
}
</style>

<template>

  <v-tabs class="w-100" v-model="tab" bg-color="" >
    <v-tab @click="clickHanro" value="tab_1">坂路</v-tab>
    <v-tab @click="clickWc" value="tab_2">ウッドチップ</v-tab>
  </v-tabs>

  <div class="w-100" style="overflow-x: scroll;">
    <v-window disabled v-model="tab" style="min-height: 500px;margin-top:20px;">
      <v-window-item value="tab_1">
        <v-table ref="Table" class="w-100 mtable" density="compact">
          <thead :key="horses">
            <tr>
              <th v-for="(head,index) in table_headders" :key="head" :class="headStick(index)" v-html="head[0]" :style="head[1]" height="44px" >
              </th>
              <template v-for="index in maxHist" :key="index">
                <th class="text-center" style="min-width:100px;">日付<br>コース</th>
                <th class="text-center" style="min-width:320px;">
                  <div class="d-flex w-100 flex-column justify-center">
                    <div class="d-flex w-100 justify-space-around">
                      <p>F4</p><p>F3</p><p>F2</p><p>F1(基準差)</p>
                    </div>
                    <p>ラップ</p>
                  </div>
                </th>
              </template>
            </tr>
          </thead>
          <tbody v-if="tab == 0" :key="horses">
            <tr v-for="(horse,index) in horses" :key="horse" class="text-center">
              <td class="font-weight-bold sticky" :style='[index%2 == 1 ? "background-color: #f9f9f9;" : "background-color: #ffff;"]'>
                <p>{{ horse.umaban ? horse.umaban : "" }}</p>
                <router-link class="font-weight-bold" :aria-label="horse.name+'の統計データ'" :to="{name:'Horse',params:{umaId:horse.uma_id,umaName:horse.name}}">
                  <p @click.stop.prevent="clickHorse(horse)">{{ horse.name.slice(0,3) }}</p>
                </router-link>
              </td>
              <td class="sticky2 font-weight-bold" :style='getMarkStyle(horse,index)'>{{ horse.mark ? decodeURI(horse.mark.mark) : "-" }}</td>
              <td>{{ horse.cyoukyousi ? horse.cyoukyousi : "" }}</td>
              <template v-for="index in maxHist" :key="index">
                <td v-html="getDate(horse,index)"></td>
                <td v-html="getTime(horse,index)"></td>
              </template>
            </tr>
          </tbody>
        </v-table>
      </v-window-item>

      <v-window-item value="tab_2">
        <v-table ref="Table" class="w-100" density="compact">
          <thead :key="horses">
            <tr>
              <th v-for="(head,index) in table_headders" :key="head" :class="headStick(index)" v-html="head[0]" :style="head[1]" height="44px" >
              </th>
              <template v-for="index in maxHist" :key="index">
                <th class="text-center" style="min-width:100px;">日付<br>コース</th>
                <th class="text-center" style="min-width:480px;">
                  <div class="d-flex w-100 flex-column justify-center">
                    <div class="d-flex w-100 justify-space-around">
                      <p>F6</p><p>F5</p><p>F4</p><p>F3</p><p>F2</p><p>F1(基準差)</p>
                    </div>
                    <p>ラップ</p>
                  </div>
                </th>
              </template>
            </tr>
          </thead>
          <tbody v-if="tab == 1" :key="horses">
            <tr v-for="(horse,index) in horses" :key="horse" class="text-center">
              <td class="font-weight-bold sticky" :style='[index%2 == 1 ? "background-color: #f9f9f9;" : "background-color: #ffff;"]'>
                <p>{{ horse.umaban ? horse.umaban : "" }}</p>
                <router-link class="font-weight-bold" :aria-label="horse.name+'の統計データ'" :to="{name:'Horse',params:{umaId:horse.uma_id,umaName:horse.name}}">
                  <p @click.stop.prevent="clickHorse(horse)">{{ horse.name.slice(0,3) }}</p>
                </router-link>
              </td>
              <td class="sticky2" :style='[index%2 == 1 ? "background-color: #f9f9f9;" : "background-color: #ffff;"]'>-</td>
              <td>{{ horse.cyoukyousi ? horse.cyoukyousi : "" }}</td>
              <template v-for="index in maxHist" :key="index">
                <td v-html="getDate(horse,index)"></td>
                <td v-html="getTime(horse,index)"></td>
              </template>
            </tr>
          </tbody>
        </v-table>
      </v-window-item>
    </v-window>
  </div>

</template>

<script>
import value from "../../global.js"

export default {
  name: 'Race-training',
  emits: ["clickWc","clickHorse"],
  props:{
    horses:{
      default:[],
    },
    currentRace:{
      default:null,
    },
  },
  components: {

  },
  data: () => ({
    value:value.VALUE,
    table_headders:[["馬番","min-width:60px;"],["印","min-width:40px;"],["調教師","min-width:100px;"]],  
    maxHist:10,
    base:{"栗東":[53.6,38.8,25.4,12.6],"美浦":[53.0,38.9,25.4,12.5]},
    baseWc:{"栗東":[83.0,66.8,52.0,38.3,25.3,12.3],"美浦":[83.5,67.5,52.5,38.8,25.7,12.6]},
    tab:0,
  }),
  watch:{
    horses:function(){
      this.$refs.Table.scrollLeft = 0;
    },
  },
  created:function(){
    //this.initial();
  },
  mounted:function(){
    
  },
  methods:{
    initial(){

    },
    clickHorse(horse){
      this.$emit("clickHorse",horse);
    },
    clickHanro(){
      this.tab = 0;
    },
    clickWc(){
      this.tab = 1;
      this.$emit("clickWc");
    },
    getDate(horse,num){

      const index = num-1;

      var ary = [];
      if(this.tab == 0)ary = horse.cyoukyou;
      else ary = horse.cyoukyouWc

      if(!ary || ary.length <= index){
        return;
      }
      
      const cyou = ary[index];
      
      return `${cyou.date.slice(0,4)}/${cyou.date.slice(4,6)}/${cyou.date.slice(6,8)}<br>${cyou.kyuusya}`;
    },
    getTime(horse,num){
      var ary = [];
      if(this.tab == 0)ary = horse.cyoukyou;
      else ary = horse.cyoukyouWc

      const index = num-1;
      if(!ary || ary.length <= index){
        return;
      }
      
      const cyou = ary[index];
      var fs = [];
      var max = 3;
      if(this.tab == 0){
        fs = [cyou.f4,cyou.f3,cyou.f2,cyou.f1];
        max = 3;
      }
      else{
        fs = [cyou.f6,cyou.f5,cyou.f4,cyou.f3,cyou.f2,cyou.f1];
        max = 5;
      }

      var upStr = "";
      var lowStr = "";
      for(let n=0;n<max;n++){
        if(fs[n] == "0.0"){
          upStr += "<p class='ch_data'>-</p>";
        lowStr += "<p class='ch_data'>-</p>";
        }
        else{
          let base = 0.0;
          if(this.tab == 0)base = this.base[cyou.kyuusya][n];
          else base = this.baseWc[cyou.kyuusya][n];

          let del = (fs[n]-0.0)-base;
          upStr += `<p class="ch_data">${fs[n]}(${del.toFixed(1)})</p>`;

          let val = (fs[n]-0.0)-(fs[n+1]-0.0);
          lowStr += `<p class="ch_data">${val.toFixed(1)}</p>`;
        }
      }

      if(fs[max] == "0.0"){
        upStr += "<p class='ch_data'>-</p>";
        lowStr += "<p class='ch_data'>-</p>";
      }
      else{
        let base = 0.0;
        if(this.tab == 0)base = this.base[cyou.kyuusya][max];
        else base = this.baseWc[cyou.kyuusya][max];

        let del = (fs[max]-0.0)-base;
        upStr += `<p class="ch_data">${fs[max]}(${del.toFixed(1)})</p>`;

        lowStr += `<p class="ch_data">${fs[max]}</p>`;
      }
      
      return `<div class="d-flex w-100 flex-column justify-center">`+
          `<div class="d-flex w-100 justify-space-around">${upStr}</div>`+
          `<div class="d-flex w-100 justify-space-around">${lowStr}</div>`+
          `</div>`;

    },
  },
  computed:{
    headStick(){
      return (index) => {
        if(index == 0){
          return "text-center sticky stick_head_back";
        }
        else if(index == 1){
          return "text-center sticky2 stick_head_back";
        }
        else{
          return "text-center";
        }
      }
    },
    getMarkStyle(){
      return (horse,index) => {
        var str = ""
        if(index%2 == 1){
          str += "background-color: #f9f9f9;"
        } 
        else{
          str += "background-color: #ffff;";
        }
        if(horse.mark && horse.mark.isRed){
          str += "color:red;";
        }

        return str;
      }
    },
  }
}

</script>

<style scoped>
a {
  color:blue;
  text-decoration:none;
}
:deep(.ch_data){
  min-width: 70px;
}

:deep(.row3text){
  font-size:12px;
  line-height: 14px;
}

:deep(.row4text){
  font-size:10px;
  line-height: 12px;
}
:deep(.row1text) {
  font-size:12px;
  height: 44px;    
  line-height: 44px;
}


table thead th {
  background-color: #EFEFEF;
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  padding:4px !important;
}
table thead th:last-child {
  border-right : 1px solid #CCC;
}
.mtable tbody tr:nth-child(2n) {
  background-color: #f9f9f9;
}
table tbody td {
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  border-bottom : 1px solid #CCC;
  height:44px;
  padding:0px 8px !important;
}
table tbody td:last-child{
  border-right : 1px solid #CCC;
}

.sticky {
  position: sticky;
  top: 0;
  left: 0;
  border-top: none;
  border-bottom: none;
}
.sticky:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.sticky2 {
  position: sticky;
  top: 0;
  left: 60px;
  border-top: none;
  border-bottom: none;
}
.sticky2:before {
  content: "";
  position: absolute;
  top: 0;
  left: 60px;
  width: 100%;
  height: 100%;
  z-index: -1;
}

</style>
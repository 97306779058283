<template>
  <!--
  <v-layout>
    <v-main>
      <v-container> -->
        <v-card elevation="2">
          <h2 class="mx-3" style="margin-top:8px;">{{ uma_name }}</h2>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="5">
                <v-carousel height="300" show-arrows="hover" hide-delimiter-background style="background-color:darkgray;">
                  <template v-for="index in 3" :key="index">
                    <v-carousel-item contain :src="getImage(index)"></v-carousel-item>
                  </template>
                </v-carousel>
                <div class="d-flex justify-end d-none">
                    <v-btn class="my-1" @click="postDialog = true;">写真投稿</v-btn>
                </div>
              </v-col>

              <v-col cols="12" md="7">
                <v-sheet class="px-2 py-2" elevation="2">
                  <p v-if="res">{{ res.sex }} {{  getAge(res.birth) }} {{ res.color }}</p>
                  <p><span class="sub">成年月日</span><span v-if="res">{{ `${res.birth.slice(0,4)}年${res.birth.slice(4,6)}月${res.birth.slice(6,8)}日` }}</span></p>
                  <p><span class="sub">獲得賞金</span><span v-if="res">{{ res.prize ? Math.floor(res.prize/10000).toLocaleString() + "万円" : "" }}</span></p>
                  <p><span class="sub">調教師</span><span v-if="res">{{ `${res.cyoukyousi}(${res.kyusya})` }}</span></p>
                  <p style="margin-bottom:14px;"><span class="sub">馬主</span><span v-if="res">{{ res.banusi }}</span></p>
                  <v-table density="compact">
                    <tbody class="text-center">
                      <tr>
                        <td rowspan="2" class="osu">{{ this.kettou[0] }}</td>
                        <td class="osu">{{ this.kettou[2] }}</td>
                      </tr>
                      <tr>
                        <td class="mesu">{{ this.kettou[3] }}</td>
                      </tr>
                      <tr>
                        <td class="mesu" rowspan="2">{{ this.kettou[1] }}</td>
                        <td class="osu">{{ this.kettou[4] }}</td>
                      </tr>
                      <tr>
                        <td class="mesu">{{ this.kettou[5] }}</td>
                      </tr>
                    </tbody>
                  </v-table>
                  <div class="d-flex justify-end">
                    <v-btn class="my-1" @click='clickKettou()' v-show="res">5代血統</v-btn>
                  </div>
                </v-sheet>
                
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-sheet class="px-2 py-2" elevation="2">
                  <h3>成績</h3>
                  <v-row>
                    <template v-for="data in chartDatas" :key="data">
                      <v-col cols="6" sm="4" md="3" lg="2">
                        <Doughnut class="ch_title ch_label" data-unit="回" :data="data" :options="chartOptions" :key="data" />
                      </v-col>
                    </template>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-sheet class="px-2 py-2" elevation="2">
                  <v-row>
                    <v-col cols="12" md="6">
                      <h3>成長曲線</h3>
                      <Line :data="growthData" :options="growthOptions" :key="growthData" />
                    </v-col>
                    <v-col cols="12" md="6">
                      <h3>人気-支持率</h3>
                      <Line :data="sijiData" :options="sijiOptions" :key="sijiData" />
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>

            <v-row v-if="res">
              <v-col cols="12">
                <v-sheet class="px-2 py-2" elevation="2">
                  <h3>レース履歴</h3>
                  <v-table>
                    <tbody>
                      <template v-for="hashira in res.hashira" :key="hashira">
                        <tr>
                          <td>
                            <router-link class="no_style_a" :aria-label="getAriaLabel(hashira.race)" :to="{name: 'Race', params:{date:hashira.race.date,place:hashira.race.place,round:hashira.race.round,name:hashira.race.race_name}}">
                              <div @click.stop.prevent="clickHist(hashira.race)" v-html="getHist(hashira)" v-ripple style="cursor: pointer;"></div>
                            </router-link>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </v-table>
                </v-sheet>
              </v-col>
            </v-row>
          </v-col>
        </v-card>

        <v-dialog v-model="isBloodDialog" class="w-dialog">
          <div class="d-flex justify-end">
            <v-btn class="mb-2" icon @click="isBloodDialog = false" >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <Blood :umaId="uma_id" :umaName="uma_name" />
        </v-dialog>

        <v-dialog v-model="postDialog" class="w-dialog">
          <v-card class="w-100" >
            <Coming />
          </v-card>
        </v-dialog>

        <!-- race diaolog -->
        <v-dialog v-model="raceDialog" class="w-dialog pa-0">
          <div class="d-flex justify-end">
            <v-btn class="mb-2" icon @click="raceDialog = false" >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <RaceDetaileContainer :CurrentRace="modalRace" :IsModal="true" :key="modalRace.id"/>
        </v-dialog>
<!--
      </v-container>
    </v-main>
  </v-layout> -->
</template>

<script>
import value from "../../global.js"
import axios from 'axios'

import { useHead } from '@unhead/vue'
//import router from "@/router";

import Coming from "@/components/Coming.vue";
import Blood from "@/components/stats/Blood.vue"

import RaceDetaileContainer from "@/components/race/RaceDetaileContainer.vue"

import { Doughnut, Line} from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, LineElement, PointElement, LinearScale, CategoryScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement, LineElement, PointElement, LinearScale, CategoryScale);


export default {
  name: 'Horse-',
  emits: ["clickHist"],
  props:{
    umaId:{
      default:"",
    },
    umaName:{
      default:"",
    },
    isDialog:{
      default:false,
    },
  },
  components: {
    Doughnut,
    Line,
    Coming,
    Blood,
    RaceDetaileContainer,
  },
  /*head:{
		title:"競走馬データ",
		meta:[
			{ name:"description",content:"競走馬の詳細データ。脚質や得意なコース、開催地を表示。年齢毎の体重や成績をわかりやすい成長曲線で表示。" },
			{ property: "og:type", content: "website" },
			{ property: "og:url", content: process.env.VUE_APP_DOMAIN+"/state/horse" },
			{ property: "og:title", content: "pakara - 競馬の総合情報サイト" },
			{ property: "og:description", content: "競走馬の詳細データ。脚質や得意なコース、開催地を表示。年齢毎の体重や成績をわかりやすい成長曲線で表示。" },
			{ property: "og:site_name", content: "pakara 競馬の予想、投票と収支管理" },
			{ property: "og:image", content: "https://pakara-keiba.com/ogp.png" },
		],
	},*/
  data: () => ({
    value:value.VALUE,
    postDialog:false,
    isBloodDialog:false,
    raceDialog:false,
    res:null,
    uma_id:"",
    uma_name:"",
    kettou:["","","","","",""],
    modalRace:null,

    chartOptions:{
      responsive: true,
      maintainAspectRatio: false,
      plugins:{
        legend: {
          position:"bottom",
          labels:{boxWidth:10, boxHeight:10,padding:0,font:{size:10}},
        },
      },
    },
    growthOptions:{
      responsive: true,
      maintainAspectRatio: true,
      plugins:{
        legend: {
          position:"bottom",
          align:"start",
          labels:{boxWidth:30, boxHeight:10,font:{size:12}},
        },
      },
      scales:{
        xAxis:{
          type:"linear",
          grace:"0",
          ticks:{
            callback:(day) => {
              const ye = day/365.0;
              const mo = (ye - Math.floor(ye))*12;

              return Math.floor(ye)+"歳"+Math.floor(mo)+"ヶ月";
            },
          }
        },
        rank:{
          id:"rank",
          //type: "linear",
          position:"right",
          min:1,
          max:18,
          scaleLabel:{
            display:true,
            fontColor:"#fe0100",
          },
          ticks:{
            min:0,
            max:18,
            stepSize:1,
            //beginAtZero: true,
            callback:(value) => {
              if(value == 18)return "";
              return `${18-value}位`
            },
          },
        },
        weight:{
          id:"weight",
          //type: "linear",
          position:"left",
          min:400,
          max:550,
          ticks:{
            min:400,
            max:550,
            //beginAtZero: true,
          },
        },
      },
    },
    sijiOptions:{
      responsive: true,
      maintainAspectRatio: true,
      plugins:{
        legend: {
          position:"bottom",
          align:"start",
          labels:{boxWidth:30, boxHeight:10,font:{size:12}},
        },
      },
      scales:{
        xAxis:{
          type:"linear",
          grace:"0",
          ticks:{
            callback:(day) => {
              const ye = day/365.0;
              const mo = (ye - Math.floor(ye))*12;

              return Math.floor(ye)+"歳"+Math.floor(mo)+"ヶ月";
            },
          }
        },
        rank:{
          id:"rank",
          //type: "linear",
          position:"right",
          min:1,
          max:18,
          scaleLabel:{
            display:true,
            fontColor:"#ff0000",
          },
          ticks:{
            min:0,
            max:18,
            stepSize:1,
            //beginAtZero: true,
            callback:(value) => `${18-value}位`,
          },
        },
        siji:{
          id:"siji",
          //type: "linear",
          position:"left",
          min:0,
          max:100,
          ticks:{
            callback:(value) => `${value}%`,
            //beginAtZero: true,
          },
        }
      },
    },
    chartDatas:[],
    chakuData: {},
    kyakuData: {},
    corseData: {},
    disData: {},
    mawariData: {},
    placeData: {},
    growthData:  {
      labels: [],
      datasets: [{
        label: "",
        data: [],
        backgroundColor: "#f2f2f2",
      }],
    },
    sijiData: {
      labels: [],
      datasets: [{
        label: "",
        data: [],
        backgroundColor: "#f2f2f2",
      }],
    },
  }),
  watch:{
    umaId(val){
      this.uma_id = val;
      this.fetchData();
    },
    umaName(val){
      this.uma_name = val;
      if(!this.isDialog){
        useHead(this.getHead());
      }
    }
  },
  created:function(){
    
    if(this.$route.params.umaId && this.$route.params.umaId != -1){
      this.uma_id = this.$route.params.umaId;
      this.uma_name = this.$route.params.umaName;
    }
    else{
      this.uma_id = this.umaId;
      this.uma_name = this.umaName;
    }

    if(!this.isDialog){
      useHead(this.getHead());
    }

    this.initial();
    this.fetchData();
  },
  mounted:function(){

  },
  methods:{
    initial(){
      let emptyData = {
        labels: [],
        datasets: [{
          label: "",
          data: [],
          hoverOffset: 4,
        }],
      };

      for(let n=0;n<6;n++){
        this.chartDatas.push(emptyData);
      }

      this.growthData = {
        labels: [],
        datasets: [{
          label: "",
          data: [],
          backgroundColor: "#f2f2f2",
        }],
      };
      this.sijiData = {
        labels: [],
        datasets: [{
          label: "",
          data: [],
          backgroundColor: "#f2f2f2",
        }],
      };

      this.res = null;
    },
    getHead(){
      return {
        title: this.uma_name+"の統計データ",
        meta:[
          { name:"description",content:"競走馬の詳細データ。脚質や得意なコース、開催地を表示。年齢毎の体重や成績をわかりやすい成長曲線で表示。" },
          { property: "og:type", content: "website" },
          { property: "og:url", content: process.env.VUE_APP_DOMAIN+"/stats/horse/"+this.uma_id+"/"+this.uma_name },
          { property: "og:title", content: this.uma_name+"の統計データ" },
          { property: "og:description", content: "競走馬の詳細データ。脚質や得意なコース、開催地を表示。年齢毎の体重や成績をわかりやすい成長曲線で表示。" },
          { property: "og:site_name", content: "pakara 競馬の予想、投票と収支管理" },
          { property: "og:image", content: "https://pakara-keiba.com/ogp.png" },
        ],
        script:[
          { 
            id:"json",
            type:"application/ld+json" ,
            innerHTML:'[{\
                "@context": "http://schema.org",\
                "@type": "BreadcrumbList",\
                "itemListElement": [\
                {\
                  "@type": "ListItem",\
                  "position": 1,\
                  "item": {\
                    "@id": "http://localhost:8080/stats/race/",\
                    "name": "競馬の過去10年分の統計データ"\
                  }\
                },\
                {\
                  "@type": "ListItem",\
                  "position": 2,\
                  "item": {\
                    "@id": "'+location.href+'",\
                    "name": "'+this.uma_name+'の統計データ"\
                  }\
                }]\
              },\
              {\
                "@context": "http://schema.org",\
                "@type": "WebSite",\
                "name": "pakara - 競馬の総合情報サイト",\
                "url": "https://pakara-keiba.com/"\
              }\
            ]',
          }
        ]
      };
    },
    getAriaLabel(race){
			const label = race.date.slice(0,4)+"年"+race.date.slice(4,6)+"月"+race.date.slice(-2)+"日 "+this.value.PLACE_NAME[race.place]+race.round+"R "+race.race_name+"の出馬表";
			return label;
		},
    clickHist(race){
      /*if(!this.isDialog){
        this.modalRace = race;
        this.raceDialog = true;
      }
      else{*/
        this.$emit("clickHist",race);
      //}
    },
    reloadFromParent(){
      this,this.initial();
      this.fetchData();
    },
    clickKettou(){
      this.isBloodDialog = true;
    },
    fetchData(){
      var params = new URLSearchParams();
      params.append("uma_id", this.uma_id);
        
              
      axios.post(process.env.VUE_APP_POST_URL+"/race/get_uma_detail_v2.8.0.php",params)      
        .then(response => {
          //console.log(response.data);
        if(response.data.length == 0){
          this.res = null;
          return;
        }

        this.res = response.data;

        this.kettou = this.res.kettou.split("-");

        if(this.res.hashira.length > 0){
          let sum = 0;
          let result = [0,0,0,0,0,0];
          let kyaku = {"逃げ":0,"先行":0,"差し":0,"追込":0};
          let course = {"芝":[0,0,0,0,0,0],"ダ":[0,0,0,0,0,0],"障":[0,0,0,0,0,0]};
          let distance = [[0,0,0,0,0,0],[0,0,0,0,0,0],[0,0,0,0,0,0]];

          let mawari = [[0,0,0,0,0,0],[0,0,0,0,0,0]];

          let place = [];
          for(let n=0;n<10;n++)place[n] = [0,0];
          

          let da = new Date(this.res.birth.slice(0,4)+"/"+this.res.birth.slice(4,6)+"/"+this.res.birth.slice(-2)+" 00:00");
          let dates = [];
          let weights = [];
          let ranks = [];
          let ninkis = [];
          let sijis = [];

          for(let n=0;n<this.res.hashira.length;n++){
            const ha = this.res.hashira[n];
            if(ha.horse.rank > 0 && ha.horse.rank < 100){
              const rank = ha.horse.rank;
              if(rank < 6){
                result[rank-1] += 1;
                course[ha.race.course][rank-1] += 1;

                const dis = this.checkKyori(ha.race.distance);
                distance[dis][rank-1] += 1;

                const mawa = this.checkMawari(ha.race.place);
                mawari[mawa][rank-1] += 1;
              }
              else{
                result[5] += 1;
                course[ha.race.course][5] += 1;

                const dis = this.checkKyori(ha.race.distance);
                distance[dis][5] += 1;

                const mawa = this.checkMawari(ha.race.place);
                mawari[mawa][5] += 1;
              }
              
              if(ha.horse.kyaku)kyaku[ha.horse.kyaku] += 1;

              if(rank <= 3)place[ha.race.place][0] += 1;
              else place[ha.race.place][1] += 1;

              let raceData = new Date(ha.race.date.slice(0,4)+"/"+ha.race.date.slice(4,6)+"/"+ha.race.date.slice(-2)+" 00:00");
              let diff = raceData.getTime() - da.getTime();
              let diffDay = Math.floor(diff/(1000*60*60*24));

              dates.push(diffDay);
              weights.push({x:diffDay,y:ha.horse.weight});
              ranks.push({x:diffDay,y:18-ha.horse.rank});
              ninkis.push({x:diffDay,y:18-ha.horse.ninki})

              let siji = 0.0;
              if(ha.horse.odds){
                siji = 0.78/(ha.horse.odds-0.1)*100;
              }
              sijis.push({x:diffDay,y:siji});

              sum += 1;
              
            }
          }

          if(sum != 0){
          // grade
            this.chakuData = {
              labels: ['1着','2着','3着',"4着","5着","6着~"],
              datasets: [{
                label: '成績',
                data: result,
                backgroundColor: ["#80fdff","#80e5fd","#7fccfe","#ffa580","#fe987f","#fe8c81"],
                hoverOffset: 4,
              }],
            };

          //kyaku
            const kStr = ["逃げ","先行","差し","追込"];
            let kyakuRes = [0,0,0,0];
            for(let n=0;n<kStr.length;n++){
              kyakuRes[n] = kyaku[kStr[n]];
            }

            this.kyakuData = {
              labels: ["逃げ","先行","差し","追込"],
              datasets: [{
                label: "脚質",
                data: kyakuRes,
                backgroundColor: ["#ff7f81","#99ff80","#fff394","#5ac6fa"],
                hoverOffset: 4,
              }],
            };

          //corse
            let couCol = [[],[],[]];
            for(let n=0;n<3;n++)couCol[0].push(this.hsvTohex(142,(0.94-0.1*n)*100,90));
            for(let n=3;n<5;n++)couCol[0].push(this.hsvTohex(142,(0.94-0.1*n)*100,70));
            couCol[0].push(this.hsvTohex(142,(0.94-0.1*5)*100,50));

            for(let n=0;n<3;n++)couCol[1].push(this.hsvTohex(34,(0.94-0.1*n)*100,90));
            for(let n=3;n<5;n++)couCol[1].push(this.hsvTohex(34,(0.94-0.1*n)*100,70));
            couCol[1].push(this.hsvTohex(34,(0.94-0.1*5)*100,50));

            for(let n=0;n<3;n++)couCol[2].push(this.hsvTohex(190,(0.94-0.1*n)*100,90));
            for(let n=3;n<5;n++)couCol[2].push(this.hsvTohex(190,(0.94-0.1*n)*100,70));
            couCol[2].push(this.hsvTohex(190,(0.94-0.1*5)*100,50));

            const str = ["1着","2着","3着","4着","5着","6着~"]
            const  cStr = ["芝","ダ","障"];

            let cCol = [];
            let cLabels = [];
            let cData = [];
            for(let n=0;n<Object.keys(course).length;n++){
              for(let i=0;i<course[cStr[n]].length;i++){
                let val = course[cStr[n]][i];
                if(val != 0){
                  cData.push(val);
                  cLabels.push(cStr[n]+str[i]);
                  cCol.push(couCol[n][i]);
                }
              }
            }

            this.corseData = {
              labels: cLabels,
              datasets: [{
                label: 'コース',
                data: cData,
                backgroundColor: cCol,
                hoverOffset: 4,
              }]
            };

          //distance
            let disCol = [[],[],[]];
            for(let n=0;n<3;n++)disCol[0].push(this.hsvTohex(84,(0.94-0.1*n)*100,100));
            for(let n=3;n<5;n++)disCol[0].push(this.hsvTohex(84,(0.94-0.1*n)*100,90));
            disCol[0].push(this.hsvTohex(84,(0.94-0.1*5)*100,70));

            for(let n=0;n<3;n++)disCol[1].push(this.hsvTohex(25,(0.94-0.1*n)*100,100));
            for(let n=3;n<5;n++)disCol[1].push(this.hsvTohex(25,(0.94-0.1*n)*100,90));
            disCol[1].push(this.hsvTohex(25,(0.94-0.1*5)*100,70));

            for(let n=0;n<3;n++)disCol[2].push(this.hsvTohex(212,(0.94-0.1*n)*100,100));
            for(let n=3;n<5;n++)disCol[2].push(this.hsvTohex(212,(0.94-0.1*n)*100,90));
            disCol[2].push(this.hsvTohex(212,(0.94-0.1*5)*100,70));

            const dStr = ["短","中","長"];
            let dCol = [];
            let dLabels = [];
            let dData = [];
            
            for(let n=0;n<distance.length;n++){
              for(let i=0;i<distance[n].length;i++){
                let val = distance[n][i];
                if(val != 0){
                  dData.push(val);
                  dLabels.push(dStr[n]+str[i])
                  dCol.push(disCol[n][i]);
                }
              }
            }

            this.disData = {
              labels: dLabels,
              datasets: [{
                label: "距離",
                data: dData,
                backgroundColor: dCol,
                hoverOffset: 4,
              }],
            };

          //mawari
            let mawCol = [[],[]];
            for(let n=0;n<3;n++)mawCol[0].push(this.hsvTohex(154,(0.94-0.1*n)*100,100));
            for(let n=3;n<5;n++)mawCol[0].push(this.hsvTohex(154,(0.94-0.1*n)*100,90));
            mawCol[0].push(this.hsvTohex(154,(0.94-0.1*5)*100,70));

            for(let n=0;n<3;n++)mawCol[1].push(this.hsvTohex(57,(0.94-0.1*n)*100,100));
            for(let n=3;n<5;n++)mawCol[1].push(this.hsvTohex(57,(0.94-0.1*n)*100,90));
            mawCol[1].push(this.hsvTohex(57,(0.94-0.1*5)*100,70));

            let mStr = ["右","左"];
            let mCol = [];
            let mLabels = [];
            let mData = [];

            for(let n=0;n<mawari.length;n++){
              for(let i=0;i<mawari[n].length;i++){
                let val = mawari[n][i];
                if(val != 0){
                  mData.push(val);
                  mLabels.push(mStr[n]+str[i]);
                  mCol.push(mawCol[n][i]);
                  
                }
              }
            }

            this.mawariData = {
              labels: mLabels,
              datasets: [{
                label: '回り',
                data: mData,
                backgroundColor: mCol,
                hoverOffset: 4,
              }],
            };
          
          //place
            let pLabels = [];
            let pCol = [];
            let pData = [];


            for(let n=0;n<place.length;n++){
              for(let i=0;i<place[n].length;i++){
                let val = place[n][i];
                if(val != 0){
                  if(i == 0){
                    pData.push(val);
                    pCol.push(this.value.PLACE_COLORS[n]);
                    pLabels.push(this.value.PLACE_NAME[n]+"~3着");
                  }
                  else{
                    pData.push(val);
                    pCol.push(this.placeBadColor(n));
                    pLabels.push(this.value.PLACE_NAME[n]+"4着~");
                  }
                }
              }
            }

            this.placeData = {
              labels: pLabels,
              datasets: [{
                label: '開催地',
                data: pData,
                backgroundColor: pCol,
                hoverOffset: 4,
              }],
            };

            this.chartDatas = [this.chakuData,this.kyakuData,this.corseData,this.disData,this.mawariData,this.placeData];

          //growth
            let weightData = {
              //type:"line",
              label: "体重",
              data: weights.reverse(),
              borderColor: "#80d2f8",
              backgroundColor: "#80d2f8",
              yAxisID:"weight",
              borderWidth:1,
              //order:1,
            };

            let rankData = {
              //type:"line",
              label: "着順",
              data: ranks.reverse(),
              borderColor: "#fe0100",
              backgroundColor:"#fe0100",
              yAxisID:"rank",
              borderWidth:1,
              //order:2,
            };

            this.growthOptions.scales.xAxis.min = dates[dates.length-1];
            this.growthOptions.scales.xAxis.max = dates[0];

            this.growthData = {
              datasets: [weightData,rankData],
            };


            let siData = {
              label:"支持率",
              data:sijis.reverse(),
              borderColor:"#47a8ff",
              backgroundColor:"#47a8ff",
              yAxisID:"siji",
              borderWidth:1,
            };

            let ninkiData = {
              label:"人気",
              data:ninkis.reverse(),
              borderColor:"#ff944c",
              backgroundColor:"#ff944c",
              yAxisID:"rank",
              borderWidth:1,
            };

            this.sijiOptions.scales.xAxis.min = dates[dates.length-1];
            this.sijiOptions.scales.xAxis.max = dates[0];

            this.sijiData = {
              datasets:[siData,ninkiData,rankData],
            }
          
          }
        }

      })
      .catch(error => {
        console.log(error);
      });
    },
    getHist(hashira){

      const hist = hashira.horse;

      let rankCss = "";
      var rank = hist.rank;
      if(rank > 99){
        rank = this.value.JYOGAI_CODE[""+rank];
        rankCss = "rank_jyogai";
      }
      else if(rank <= 3)rankCss = "text-red";

      let ninki = hist.ninki;
      let ninkiCss = "";
      if(ninki <= 5)ninkiCss = "text-red";
      ninki = ninki + "人気";

      const race = hashira.race;

      const date = race.date.slice(2,4)+"."+race.date.slice(4,6)+"/"+race.date.slice(-2);
      const desc = `${this.value.PLACE_NAME[race.place]} ${race.count}頭 馬場:${race.baba}`;

      const horDesc = ` ${hist.weight}(${hist.zougen}) ${hist.kyaku} ${hist.kisyu}(${hist.kinryou})`;


      return `<div class="d-flex align-center">`+
                `<div class="rank_box rank_border d-flex flex-column">`+
                  `<div class="rank_box_rank ${rankCss}">${rank}</div>`+
                `</div>`+
                '<div class="d-flex justify-start" style="width: 84%;">'+
                  `<div class="row3text mx-2 text-start" style="width:220px;">`+
                    `<p>${date} ${desc}</p>`+
                      `<p>`+
                        `<span style="font-weight:bold;">${race.race_name}</span>`+
                        `<span style="color:${this.value.COURSE_COLOR[race.course]};"> ${race.course}</span>`+
                        ` ${race.distance} ${this.value.WEATHER[race.weather]}`+
                      `</p>`+
                    `<p>`+
                      `<span class="text-center waku" style="${this.wakuColorStr(hist.waku)}">${hist.umaban}</span>`+
                      `<span>${horDesc}</span>`+
                    `</p>`+
                  `</div>`+
                  `<div class="row3text text-center" style="width:50px;">`+
                    `<p>${hist.time}</p>`+
                    `<p>${hist.f3}</p>`+
                    `<p>${hist.cyakusa}</p>`+
                  `</div>`+
                  `<div style="width:50px;text-align:center;margin-left:10%;">`+
                    `<p class=${ninkiCss}>${ninki}</p>`+
                    `<p>${hist.odds}</p>`+
                  `<div>`+
                `</div>`+
              `</div>`;

    },
    getAge(date){

      const birthDate = new Date(date.slice(0,4)+"/"+date.slice(4,6)+"/"+date.slice(-2)+" 00:00");
      const now = new Date();

      const diff = now.getTime() - birthDate.getTime();
      const diffDay = Math.floor(diff/(1000*60*60*24));

      return this.dayToAge(diffDay);

    },
    dayToAge(day){
      const ye = day/365.0;
      const mo = (ye - Math.floor(ye))*12;

      return Math.floor(ye)+"歳"+Math.floor(mo)+"ヶ月";
    },
    getImage(num){
      const index = num-1;

      if(this.res == null || this.res.images == undefined || this.res.images.length <= index){
        return require("@/assets/image/no_image.webp");
      }
      
      return process.env.VUE_APP_DOMAIN+"/post_images"+this.res.images[index].path;

    },
    checkMawari(place){
      switch (place) {
        case 0:
          return 1;
        case 1:
          return 0;
        case 2:
          return 0;
        case 3:
          return 0;
        case 4:
          return 1;
        case 5:
          return 0;
        case 6:
          return 0;
        case 7:
          return 1;
        case 8:
          return 0;
        case 9:
          return 0;
        default:
          return 0;
        }
    },
    checkKyori(dis){
      /*if(dis == 1600){
        return 3;
      }
      else */if(dis < 1600){
        return 0;
      }
      else if(dis <= 2200){
        return 1;
      }
      else{
        return 2;
      }  
    },
    wakuColor(){
      return (waku) => {
        if(waku == 1){
          return {"background-color":"#fff","color":"#000"};
        }
        else if(waku == 2){
          return {"background-color":this.value.WAKU_COLOR[waku],"color":"#fff"};
        }
        else{
          return {"background-color":this.value.WAKU_COLOR[waku],"color":"#000"};
        }
      }
    },
    wakuColorStr(){
      return (waku) => {
        if(waku == 1){
          return "background-color:#fff;color:#000;";
        }
        else if(waku == 2){
          return "background-color:"+this.value.WAKU_COLOR[waku]+";color:#fff;";
        }
        else{
          return "background-color:"+this.value.WAKU_COLOR[waku]+";color:#000;";
        }
      }
    },
    hsvTohex(hue, saturation, value) {
      var result = "#fff";

      if (((hue || hue === 0) && hue <= 360) && ((saturation || saturation === 0) && saturation <= 100) && ((value || value === 0) && value <= 100)) {
        var red   = 0,
            green = 0,
            blue  = 0,
            i     = 0,
            f     = 0,
            q     = 0,
            p     = 0,
            t     = 0;

        hue        = Number(hue)        / 60;
        saturation = Number(saturation) / 100;
        value      = Number(value)      / 100;

        if (saturation === 0) {
          red   = value;
          green = value;
          blue  = value;
        } else {
          i = Math.floor(hue);
          f = hue - i;
          p = value * (1 - saturation);
          q = value * (1 - saturation * f);
          t = value * (1 - saturation * (1 - f));

          switch (i) {
            case 0 :
              red   = value;
              green = t;
              blue  = p;
              break;
            case 1 :
              red   = q;
              green = value;
              blue  = p;
              break;
            case 2 :
              red   = p;
              green = value;
              blue  = t;
              break;
            case 3 :
              red   = p;
              green = q;
              blue  = value;
              break;
            case 4 :
              red   = t;
              green = p;
              blue  = value;
              break;
            case 5 :
              red   = value;
              green = p;
              blue  = q;
              break;
          }
        }

        result = "#" + ("00"+Math.round(red   * 255).toString(16)).slice(-2)+
        ("00"+Math.round(green * 255).toString(16)).slice(-2)+
        ("00"+Math.round(blue  * 255).toString(16)).slice(-2);
      }

      return result;
    },
    rgbTohsv ( rgb ) {
      var r = rgb[0] / 255 ;
      var g = rgb[1] / 255 ;
      var b = rgb[2] / 255 ;

      var max = Math.max( r, g, b ) ;
      var min = Math.min( r, g, b ) ;
      var diff = max - min ;

      var h = 0 ;

      switch( min ) {
        case max :
          h = 0 ;
        break ;

        case r :
          h = (60 * ((b - g) / diff)) + 180 ;
        break ;

        case g :
          h = (60 * ((r - b) / diff)) + 300 ;
        break ;

        case b :
          h = (60 * ((g - r) / diff)) + 60 ;
        break ;
      }

      var s = max == 0 ? 0 : diff / max ;
      var v = max ;

      return [ h, s, v ] ;
    },
    placeBadColor(n){
      const base = this.value.PLACE_COLORS[n];

      const red = parseInt(base.slice(1,3),16);
      const green = parseInt(base.slice(3,5),16);
      const blue = parseInt(base.slice(5,7),16);
      const hsv = this.rgbTohsv([red,green,blue]);

      return this.hsvTohex(hsv[0],(hsv[1] - 0.2)*100,70);
    }
  },
  computed:{
  }
}

</script>

<style scoped>
a {
  text-decoration:none;
}
.sub {
  color:#6c6c6c;
  font-size:12px;
  margin-right:6px;
}
.w-dialog{
  width:90%;
}

@media screen and (max-width: 600px) {
  .w-dialog{
    width:100%;
  }
}

:deep(.osu){
  font-weight:bold;
  color: #4cc9ff;
}
:deep(.mesu){
  font-weight:bold;
  color: #ff67c2;
}

:deep(.row3text){
  font-size:12px;
  line-height: 14px;
}

:deep(.rank_border){
  border:1px solid darkgray;
}
:deep(.rank_box){
  width:38px;
  height: 38px;
}
:deep(.rank_box_rank){
  font-size: 23px;
  font-weight: bold;
  text-align: center;
  line-height: 37px;
}
:deep(.rank_jyogai){
  font-size: 12px;
}

table thead th {
  background-color: #EFEFEF;
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  padding:4px !important;
}
table thead th:last-child {
  border-right : 1px solid #CCC;
}

table tbody td {
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  border-bottom : 1px solid #CCC;
  padding:0px 8px !important;
}
table tbody td:last-child{
  border-right : 1px solid #CCC;
}


</style>
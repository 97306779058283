<template>
  <div class="w-100" style="overflow-x: scroll;">
  <v-table ref="Table" class="w-100 mtable" density="compact">
    <thead :key="horses">
      <tr>
        <th v-for="(head,index) in table_headders" :key="head" :class="headStick(index)" v-html="head[0]" :style="head[1]" height="44px" >
        </th>
        <template v-for="index in maxHist" :key="index">
          <th class="text-center" style="min-width:80px;">レース間隔<br>距離</th>
          <th class="text-center" style="min-width:300px;">{{ index == 1 ? "前": index }}走</th>
        </template>
      </tr>
    </thead>
    <tbody :key="horses">
      <tr v-for="(horse,index) in horses" :key="horse" class="text-center">
        <td @click="clickHorse" class="font-weight-bold sticky" :style='[index%2 == 1 ? "background-color: #f9f9f9;" : "background-color: #ffff;"]'>
          <p>{{ horse.umaban ? horse.umaban : "" }}</p>
          <router-link class="font-weight-bold" :aria-label="horse.name+'の統計データ'" :to="{name:'Horse',params:{umaId:horse.uma_id,umaName:horse.name}}">
            <p @click.stop.prevent="clickHorse(horse)">{{ horse.name.slice(0,3) }}</p>
          </router-link>
        </td>
        <td class="sticky2 font-weight-bold" :style='getMarkStyle(horse,index)'>{{ horse.mark ? decodeURI(horse.mark.mark) : "-" }}</td>
        <td v-html="getAverage(horse)"></td>
        <td class="text-left row3text" v-html="getGrade(horse)"></td>
        <td class="text-left kyaku" v-html="getKyakusitu(horse)"></td>
        <td>{{ getTime(horse) }}</td>
        <td class="mp-0" v-html="getRankHist(horse)"></td>
        <template v-for="index in maxHist" :key="index">
          <td v-html="getInterval(horse,index)"></td>
          <td>
          <router-link class="no_style_a" :aria-label="getAriaLabel(horse,index)" :to="getHistUrl(horse,index)">
            <div @click.stop.prevent="clickHist(horse,index)" v-html="getHist(horse,index)" v-ripple style="cursor: pointer;"></div>
          </router-link>
          </td>
        </template>
      </tr>
    </tbody>
  </v-table>
  </div>

</template>

<script>
import value from "../../global.js"

export default {
  name: 'Race-horse-hist',
  emits: ["clickHorse","clickHist"],
  props:{
    horses:{
      default:[],
    },
    currentRace:{
      default:null,
    },
  },
  components: {

  },
  data: () => ({
    value:value.VALUE,
    table_headders:[["馬番","min-width:60px;"],["印","min-width:40px;"],["5レース<br>平均","min-width:80px;"],["成績","min-width:110px;"],["脚質傾向","min-width:140px;"],["持ちタイム","min-width:110px;"],["<p>着順</p><div class='w-100 d-flex justify-space-around'><p>前走</p><p>2走</p><p>3走</p><p>4走</p><p>5走</p></div>","min-width:250px;"]],
    maxHist:9,
    user:{},
  }),
  watch:{
    horses:function(){
      //this.$refs.Table.$el.childNodes[1].childNodes[0].scrollLeft = 100;
      //console.log(this.$refs.Table.$el.childNodes[1].childNodes);
    },
  },
  created:function(){
    this.user = this.$store.getters.user;
    this.initial();
  },
  mounted:function(){

  },
  methods:{
    initial(){
      var histNum = 0;
      if(this.horses.length > 0){
        for(let n=0;n<this.horses.length;n++){
          let hor = this.horses[n];
          if(hor.hashiras){
            histNum = Math.max(histNum,hor.hashiras.length);
          }
        }
      }

      this.maxHist = Math.max(9,histNum);
    },
    clickHorse(horse){
      this.$emit("clickHorse",horse);
    },
    clickHist(horse,num){
      const index = num-1;

      if(!horse.hashiras || horse.hashiras.length <= index){
        return;
      }

      if(!this.user.isPurchase && index >= 9){
        alert("プレミアム機能です");
        return;
      }

      const race = horse.hashiras[index].race;
      this.$emit("clickHist",race);
    },
    getAverage(horse){
      if(!horse.hashiras){
        return "";
      }

      var ninki = 0;
      var rank = 0;

      var cnt = Math.min(5,horse.hashiras.length);
      var num = 0;
      for(var n=0;n<cnt;n++){
        let ha = horse.hashiras[n]
        if(ha.horse.rank > 0 && ha.horse.rank < 100){
          num += 1;
          ninki += ha.horse.ninki;
          rank += ha.horse.rank;
        }
      }
      if(num > 0){
        let ninkiAvg = (ninki/num).toFixed(1);
        let rankAvg = (rank/num).toFixed(1);

        return "<p>"+ninkiAvg+"位</p><p>"+rankAvg+"人気</p>";
      }
      else return "";

    },
    getGrade(horse){
      if(!horse.hashiras){
        return "";
      }

      var valCor = [0,0,0,0];
      var valDis = [0,0,0,0];
      var valSUm = [0,0,0,0];

      const cor = this.checkMawari(this.currentRace.place);
      const corStr = ["右回り","左回り"];
      const dis = this.checkKyori(this.currentRace.distance);
      const disStr = ["短距離","中距離","長距離","マイル"];

      for(let n=0;n<horse.hashiras.length;n++){
        let hor = horse.hashiras[n];
        const rank = hor.horse.rank;
        if(hor.horse.rank > 0 && hor.horse.rank < 100){
          if(rank <= 3){
            valSUm[rank-1] += 1;
            if(dis == this.checkKyori(hor.race.distance)){
              valDis[rank-1] += 1;
            }
            if(cor == this.checkMawari(hor.race.place)){
              valCor[rank-1] += 1;
            }
          }
          else{
            valSUm[3] += 1;
            if(dis == this.checkKyori(hor.race.distance)){
              valDis[3] += 1;
            }
            if(cor == this.checkMawari(hor.race.place)){
              valCor[3] += 1;
            }
          }
        }
      }

      return `<p>全て  ${valSUm[0]}-${valSUm[1]}-${valSUm[2]}-${valSUm[3]}</p>`
              +`<p>${corStr[cor]}  ${valCor[0]}-${valCor[1]}-${valCor[2]}-${valCor[3]}</p>`
              +`<p>${disStr[dis]}  ${valDis[0]}-${valDis[1]}-${valDis[2]}-${valDis[3]}</p>`;

    },
    getKyakusitu(horse){
      if(!horse.hashiras){
        return "";
      }

      var cnt = 0;
      var kc = [0,0,0,0];
      for(let n=0;n<horse.hashiras.length;n++){
        switch(horse.hashiras[n].horse.kyaku){
          case "逃げ":
            cnt += 1;
            kc[0] += 1;
            break;
          case "先行":
            cnt += 1;
            kc[1] += 1;
            break;
          case "差し":
            cnt += 1;
            kc[2] += 1;
            break;
          case "追込":
            cnt += 1;
            kc[3] += 1;
            break;
          default:
            break;
        }
      }

      var strs = [];
      if(cnt > 0){
        var max = 0.0;
        var index = 0;
        for(let n=0;n<4;n++){
          let val = kc[n]/cnt*100;
          strs[n] = Math.round(val)+"%";
          if(val > max){
            index = n;
            max = val;
          }
        }

        var csss = ["","","",""];
        csss[index] = "color:red;font-weight:bold;"

        return `<span style="${csss[0]}">逃 ${strs[0]}</span><span style="${csss[1]}">先 ${strs[1]}</span><br>\
                <span style="${csss[2]}">差 ${strs[2]}</span><span style="${csss[3]}">追 ${strs[3]}</span>`;

      }

      return "";
    },
    getTime(horse){
      if(!horse.hashiras){
        return "";
      }

      var min = 99999.9;
      var tm = "";
      var isHit = false;
      var isPlace = false;

      //const dateFormat = new DateFormat("m:ss.S");
      //const base = moment(0);

      for(let n=0;n<horse.hashiras.length;n++){
        const hor = horse.hashiras[n];
        if(hor.race.distance == this.currentRace.distance && hor.race.course == this.currentRace.course){
          const ary = hor.horse.time.split(":");
          if(ary.length < 2)continue;
          const val = (ary[0]-0)*60.0+ary[1]-0.0;

          if(hor.race.place == this.currentRace.place){
            if(min > val || !isPlace){
              isHit = true;
              isPlace = true;
              min = val;
              tm = hor.horse.time;
            }
          }
          else if(!isPlace){
            if(isHit){
              if(min > val){
                min = val;
                tm = hor.horse.time + " ("+this.value.PLACE_NAME[hor.race.place]+")";
              }
            }
            else{
              isHit = true;
              min = val;
              tm = hor.horse.time + " ("+this.value.PLACE_NAME[hor.race.place]+")";
            }
          }
        }
      }

      if(isHit){
        return tm;
      }
      else{
        return "-";
      }

    },
    getRankHist(horse){
      if(!horse.hashiras){
        return "";
      }

      var div = '<div class="w-100 d-flex justify-space-around">';
      for(let n=0;n<5;n++){
        if(n < horse.hashiras.length){
          let rank = horse.hashiras[n].horse.rank
          let rankCss = "text-black";

          if(rank > 99)rank = this.value.JYOGAI_CODE[""+rank];
          else if(rank <= 3) rankCss = "text-red";
          //else rank = rank+"位"

          let ninki = horse.hashiras[n].horse.ninki;
          let ninkiCss = "";
          if(ninki <= 5)ninkiCss = "text-red";

          ninki = ninki + "人気";

          div += `<div class="rank_box rank_border d-flex flex-column"><div class="rank_box_rank ${rankCss}">${rank}</div><div class="rank_box_ninki ${ninkiCss}">${ninki}</div></div>`;
        }
        else{
          div += '<div class="rank_box d-flex flex-column"></div>';
        }
      }
      div += "</div>";

      return div;

    },
    getInterval(horse,num){
      const index = num-1;

      if(!horse.hashiras || horse.hashiras.length <= index){
        return;
      }

      var hist = horse.hashiras[index].race;
      var target = [];
      if(index == 0){
        target = this.currentRace;
      }
      else{
        target = horse.hashiras[index-1].race;
      }

      var interval = "";

      var histDate = new Date(hist.date.slice(0,4)+"/"+hist.date.slice(4,6)+"/"+hist.date.slice(-2)+" 00:00");
      var raceDate = new Date(target.date.slice(0,4)+"/"+target.date.slice(4,6)+"/"+target.date.slice(-2)+" 00:00");

      var diff = raceDate.getTime() - histDate.getTime();
      var diffDay = Math.floor(diff/(1000*60*60*24));

      if(diffDay < 10){
        interval = "連闘";
      }
      else if(diffDay < 36){
        interval = Math.floor(diffDay/6-1)+"週";
      }
      else{
        interval = (diffDay/31).toFixed(1)+"ヶ月";
      }

      var dis = "";
      var del = target.distance - hist.distance;

      if(del == 0){
        dis = "同";
      }
      else if(del > 0){
        dis = "延長";
      }
      else{
        dis = "短縮";
      }


      return `<p>${interval}</p><p>${dis}</p>`;
    },
    getAriaLabel(horse,num){
      const index = num-1;

      if(!horse.hashiras || horse.hashiras.length <= index){
        return "";
      }

      const race = horse.hashiras[index].race;

			const label = race.date.slice(0,4)+"年"+race.date.slice(4,6)+"月"+race.date.slice(-2)+"日 "+this.value.PLACE_NAME[race.place]+race.round+"R "+race.race_name+"の出馬表";
			return label;
		},
    getHistUrl(horse,num){
      const index = num-1;

      if(!horse.hashiras || horse.hashiras.length <= index){
        return "";
      }

      const race = horse.hashiras[index].race;

      return {name: 'Race', params:{date:race.date,place:race.place,round:race.round,name:race.race_name}}
    },
    getHist(horse,num){
      const index = num-1;

      if(!horse.hashiras || horse.hashiras.length <= index){
        return "";
      }

      const hist = horse.hashiras[index].horse;

      let rankCss = "";
      var rank = hist.rank;
      if(rank > 99)rank = this.value.JYOGAI_CODE[""+rank];
      else if(rank <= 3)rankCss = "text-red";

      let ninki = hist.ninki;
      let ninkiCss = "";
      if(ninki <= 5)ninkiCss = "text-red";
      ninki = ninki + "人気";

      const race = horse.hashiras[index].race;

      const date = race.date.slice(2,4)+"."+race.date.slice(4,6)+"/"+race.date.slice(-2);
      const desc = `${this.value.PLACE_NAME[race.place]} ${race.count}頭 馬場:${race.baba}`;

      const horDesc = ` ${hist.weight}(${hist.zougen}) ${hist.kyaku} ${hist.kisyu}(${hist.kinryou})`;

      
      if(!this.user.isPurchase && index >= 9){
        return `<div style="position:relative;width:100%;height:100%;"><div class="d-flex align-center">`+
                `<div class="rank_box rank_border d-flex flex-column">`+
                  `<div class="rank_box_rank ${rankCss}">${rank}</div>`+
                  `<div class="rank_box_ninki ${ninkiCss}">${ninki}</div>`+
                `</div>`+
                '<div class="d-flex justify-space-between" style="width: 84%;">'+
                  `<div class="row3text mx-2 text-start">`+
                    `<p>${date} ${desc}</p>`+
                      `<p>`+
                        `<span style="font-weight:bold;">${race.race_name}</span>`+
                        `<span style="color:${this.value.COURSE_COLOR[race.course]};"> ${race.course}</span>`+
                        ` ${race.distance} ${this.value.WEATHER[race.weather]}`+
                      `</p>`+
                    `<p>`+
                      `<span class="text-center waku" style="${this.wakuColorStr(hist.waku)}">${hist.umaban}</span>`+
                      `<span>${horDesc}</span>`+
                    `</p>`+
                  `</div>`+
                  `<div class="row3text text-center">`+
                    `<p>${hist.time}</p>`+
                    `<p>${hist.f3}</p>`+
                    `<p>${hist.cyakusa}</p>`+
                  `</div>`+
                `</div>`+
              `</div><div class="blur"></div></div>`;
      }
      else{
        return `<div class="d-flex align-center">`+
                `<div class="rank_box rank_border d-flex flex-column">`+
                  `<div class="rank_box_rank ${rankCss}">${rank}</div>`+
                  `<div class="rank_box_ninki ${ninkiCss}">${ninki}</div>`+
                `</div>`+
                '<div class="d-flex justify-space-between" style="width: 84%;">'+
                  `<div class="row3text mx-2 text-start">`+
                    `<p>${date} ${desc}</p>`+
                      `<p>`+
                        `<span style="font-weight:bold;">${race.race_name}</span>`+
                        `<span style="color:${this.value.COURSE_COLOR[race.course]};"> ${race.course}</span>`+
                        ` ${race.distance} ${this.value.WEATHER[race.weather]}`+
                      `</p>`+
                    `<p>`+
                      `<span class="text-center waku" style="${this.wakuColorStr(hist.waku)}">${hist.umaban}</span>`+
                      `<span>${horDesc}</span>`+
                    `</p>`+
                  `</div>`+
                  `<div class="row3text text-center">`+
                    `<p>${hist.time}</p>`+
                    `<p>${hist.f3}</p>`+
                    `<p>${hist.cyakusa}</p>`+
                  `</div>`+
                `</div>`+
              `</div>`;
      }

    },
    checkMawari(place){
      switch (place) {
        case 0:
          return 1;
        case 1:
          return 0;
        case 2:
          return 0;
        case 3:
          return 0;
        case 4:
          return 1;
        case 5:
          return 0;
        case 6:
          return 0;
        case 7:
          return 1;
        case 8:
          return 0;
        case 9:
          return 0;
        default:
          return 0;
        }
    },
    checkKyori(dis){
      if(dis == 1600){
        return 3;
      }
      else if(dis < 1600){
        return 0;
      }
      else if(dis <= 2200){
        return 1;
      }
      else{
        return 2;
      }  
    },
  },
  computed:{
    headStick(){
      return (index) => {
        if(index == 0){
          return "text-center sticky stick_head_back";
        }
        else if(index == 1){
          return "text-center sticky2 stick_head_back";
        }
        else{
          return "text-center";
        }
      }
    },
    getMarkStyle(){
      return (horse,index) => {
        var str = ""
        if(index%2 == 1){
          str += "background-color: #f9f9f9;"
        } 
        else{
          str += "background-color: #ffff;";
        }
        if(horse.mark && horse.mark.isRed){
          str += "color:red;";
        }

        return str;
      }
    },
    wakuColor(){
      return (waku) => {
        if(waku == 1){
          return {"background-color":"transparent","color":"#000"};
        }
        else if(waku == 2){
          return {"background-color":this.value.WAKU_COLOR[waku],"color":"#fff"};
        }
        else{
          return {"background-color":this.value.WAKU_COLOR[waku],"color":"#000"};
        }
      }
    },
    wakuColorStr(){
      return (waku) => {
        if(waku == 1){
          return "background-color:#fff;color:#000;";
        }
        else if(waku == 2){
          return "background-color:"+this.value.WAKU_COLOR[waku]+";color:#fff;";
        }
        else{
          return "background-color:"+this.value.WAKU_COLOR[waku]+";color:#000;";
        }
      }
    }
  }
}

</script>

<style scoped>
a {
  color:blue;
  text-decoration:none;
}

:deep(.waku){
  width:16px;
  height:12px;
  display:inline-block;
  font-weight:bold;
  border:1px solid lightgray;
  font-size: 10px;
}

:deep(.row3text){
  font-size:12px;
  line-height: 13px;
}

:deep(.kyaku span) {
  display:inline-block;
  width:60px;
}

:deep(.rank_border){
  border:1px solid darkgray;
}
:deep(.rank_box){
  width:44px;
  height: 38px;
}
:deep(.rank_box_rank){
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
}
:deep(.rank_box_ninki){
  font-size: 12px;
}

:deep(.blur){
  background-color: #ff7777f2;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -8px;
  right: -8px;
}

table thead th {
  background-color: #EFEFEF;
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  padding:4px !important;
}
table thead th:last-child {
  border-right : 1px solid #CCC;
}
table tbody td {
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  border-bottom : 1px solid #CCC;
  height:44px;
  padding:0px 8px !important;
}
.mtable tbody tr:nth-child(2n) {
  background-color: #f9f9f9;
}
table tbody td:last-child{
  border-right : 1px solid #CCC;
}

.sticky {
  position: sticky;
  top: 0;
  left: 0;
  border-top: none;
  border-bottom: none;
}
.sticky:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.sticky2 {
  position: sticky;
  top: 0;
  left: 60px;
  border-top: none;
  border-bottom: none;
}
.sticky2:before {
  content: "";
  position: absolute;
  top: 0;
  left: 60px;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.stick_head_back{
}
</style>
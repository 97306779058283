<template>
      <div class="w-100" style="overflow-x: scroll;">
        <v-table ref="Table" class="w-100 mtable" density="compact">
          <thead :key="horses">
            <tr>
              <th v-for="head in table_headders" :key="head" v-html="head[0]" :style="head[1]" class="text-center" height="44px" >
              </th>
            </tr>
          </thead>
          <tbody :key="horses">
            <tr v-for="horse in horses" :key="horse" class="text-center">
              <td class="font-weight-bold">
                <p>{{ horse.umaban ? horse.umaban : "" }}</p>
                <router-link class="font-weight-bold" :aria-label="horse.name+'の統計データ'" :to="{name:'Horse',params:{umaId:horse.uma_id,umaName:horse.name}}">
                  <p @click.stop.prevent="clickHorse(horse)">{{ horse.name.slice(0,3) }}</p>
                </router-link>
              </td>
              <td class="font-weight-bold" :style='[ horse.mark && horse.mark.isRed ? "color:red;" : ""]'>{{ horse.mark ? decodeURI(horse.mark.mark) : "-" }}</td>
              <td class="font-weight-bold">{{ getSpeed(horse,0) }}</td>
              <td class="font-weight-bold">{{ getSpeed(horse,1) }}</td>
              <td class="font-weight-bold">{{ getSpeed(horse,2) }}</td>
            </tr>
          </tbody>
        </v-table>
      </div>
</template>

<script>
import value from "../../global.js"

export default {
  name: 'Race-training',
  props:{
    horses:{
      default:[],
    },
    currentRace:{
      default:null,
    },
  },
  components: {

  },
  data: () => ({
    value:value.VALUE,
    table_headders:[["馬番","width:7%;min-width:60px;"],["印","width:5%;min-width:40px;"],["スピード指数<br>前走","width:31%;"],["2前走","width:31%;"],["3走","width:31%;"]], 
  }),
  watch:{
    horses:function(){
      this.$refs.Table.scrollLeft = 0;
    },
  },
  created:function(){
    //this.initial();
  },
  mounted:function(){
    
  },
  methods:{
    initial(){

    },
    clickHorse(horse){
      this.$emit("clickHorse",horse);
    },
    getSpeed(horse,index){
      if(horse.speed == undefined || horse.speed.length <= index){
        return "-";
      }

      return horse.speed[index];
    },
  },
  computed:{
  }
}

</script>

<style scoped>
a {
  color:blue;
  text-decoration:none;
}

:deep(.row3text){
  font-size:12px;
  line-height: 14px;
}

:deep(.row4text){
  font-size:10px;
  line-height: 12px;
}
:deep(.row1text) {
  font-size:12px;
  height: 44px;    
  line-height: 44px;
}


table thead th {
  background-color: #EFEFEF;
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  padding:4px !important;
}
table thead th:last-child {
  border-right : 1px solid #CCC;
}

table tbody td {
  border-top : 1px solid #CCC;
  border-left : 1px solid #CCC;
  border-bottom : 1px solid #CCC;
  height:44px;
  padding:0px 8px !important;
}
table tbody td:last-child{
  border-right : 1px solid #CCC;
}
.mtable tbody tr:nth-child(2n) {
  background-color: #f9f9f9;
}

.sticky {
  position: sticky;
  top: 0;
  left: 0;
  border-top: none;
  border-bottom: none;
}
.sticky:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.sticky2 {
  position: sticky;
  top: 0;
  left: 60px;
  border-top: none;
  border-bottom: none;
}
.sticky2:before {
  content: "";
  position: absolute;
  top: 0;
  left: 60px;
  width: 100%;
  height: 100%;
  z-index: -1;
}

</style>